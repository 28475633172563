<template>
    <label
        v-if="label"
        v-bind="labelAttrs"
        :for="inputId"
        class="block text-gray-700 text-sm leading-4 ml-3 mb-1"
        v-html="label"
    ></label>
    <div class="flex relative items-center">
        <textarea
            :id="inputId"
            v-model="passedModel"
            v-bind="$attrs"
            ref="native"
            class="text-gray-900 w-full rounded-3xl border border-gray-300 focus:border-gray-500 focus:outline-none placeholder-gray-700 px-4 py-3 shadow-none"
        />
    </div>
    <span v-if="error" v-bind="errorAttrs" class="error" v-html="error"></span>
</template>

<script setup>
import { useAttrs, computed, ref } from "vue";
const $attrs = useAttrs();
const props = defineProps({
    modelValue: String,
    labelAttrs: Object,
    errorAttrs: Object,
    label: String,
    error: String
});
const inputId = computed(
    () => $attrs.id ?? "input" + (Math.random() + 1).toString(36).substring(7)
);
const emit = defineEmits(["update:modelValue"]);
const passedModel = computed({
    get: () => props.modelValue ?? $attrs.value,
    set: value => emit("update:modelValue", value)
});

const native = ref(null);
</script>

<style lang="scss" scoped></style>
