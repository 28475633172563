import { createApp } from "vue";
import csrf_token from "../csrf_token";

const el = document.getElementById("vue-app");
if (el) {
    function importAll(context) {
        const components = {};
        for (const path of context.keys()) {
            const name = path
                .split("/")
                .pop()
                .replace(/\.\w+$/, "");

            components[name] = context(path).default;
        }
        return components;
    }
    const components = {
        ...importAll(require.context("../Shared/", true, /\.vue$/i)),
        ...importAll(require.context("./", true, /\.vue$/i))
    };
    const app = createApp({
        template: document.getElementById("vue-app").innerHTML,
        components
    });
    app.mixin({
        methods: {
            csrf_token
        }
    });
    app.mount(el);
}
