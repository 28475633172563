<template>
    <div class="">
        <div class="flex justify-between mb-4">
            <p class="ml-4 text-primary-500">API Keys</p>
            <div class="flex mr-6 items-center">
                <p class="mr-2 px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg">
                    <span>2310</span> calls
                </p>
                <p class="text-gray-700 text-sm leading-6">total</p>
            </div>
        </div>
        <div v-for="token in apiTokens" :token="token" :key="apiTokens.id" class="space-y-4">
            <ApiToken
                :apiToken="token"
                :apiTokens="apiTokens"
                @rename="renameToken = $event"
                @delete="deleteToken = $event"
            />
        </div>

        <div class="space-y-4">
            <div class="flex justify-center mt-10 mb-40 md:mb-46 lg:mb-40.5">
                <button @click="createToken = true" class="flex btn btn-secondary">
                    Create a new API key
                    <img class="ml-3" src="/assets/icons/create-2.png" alt="" />
                </button>
            </div>
        </div>

        <ModalApiDelete
            v-if="deleteToken"
            @close="onDelete"
            :subjectName="deleteToken.name"
            successButtonText="Continue to Project"
            subjectTitle="API Key"
            :deletionRoute="route('dashboard.project_tokens.destroy', deleteToken)"
        />
        <ModalApiRename
            v-if="renameToken"
            @close="renameToken = false"
            :apiToken="renameToken"
            :apiTokens="apiTokens"
        />

        <ModalApiCreate
            :apiTokens="apiTokens"
            :project="project"
            v-if="createToken"
            @close="createToken = false"
        />
    </div>
</template>

<script>
import ApiToken from "@/Shared/Project/ApiToken";
import ModalApiDelete from "@/Shared/Modal/ModalApiDelete";
import ModalApiCreate from "@/Shared/Modal/ModalApiCreate";
import ModalApiRename from "@/Shared/Modal/ModalApiRename";

export default {
    components: {
        ApiToken,
        ModalApiDelete,
        ModalApiCreate,
        ModalApiRename
    },
    data() {
        return {
            isOpen: null,
            deleteToken: false,
            createToken: false,
            renameToken: false
        };
    },
    props: {
        apiTokens: Object,
        project: Object
    },
    mounted() {
        // optional
        document.addEventListener("keydown", e => {
            if (e.keyCode === 27 || e.key === "Escape") {
                this.isOpen = false;
            }
        });
        document.addEventListener("click", e => {
            if (e.target.id !== "menuMore2") {
                this.isOpen = false;
            }
        });
    },
    methods: {
        onDelete(success) {
            if (success) {
                this.$inertia.reload();
            }
            this.deleteToken = false;
        }
    }
};
</script>
