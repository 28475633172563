<template>
    <div class="flex justify-between">
        <p class="text-llg text-primary-500 mb-4 ml-6">Projects</p>
        <div class="flex mr-6">
            <p class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg mb-4 mr-2">
                <span>300</span> calls
            </p>
            <p class="text-sm text-gray-700 mb-4">total</p>
        </div>
    </div>
    <div v-for="project in projects" :key="project.id">
        <Project :project="project" @delete="deleteProject = $event" />
    </div>

    <ModalApiDelete
        v-if="deleteProject"
        :subjectName="deleteProject.name"
        subjectTitle="Project"
        successButtonText="Continue to Dashboard"
        :deletionRoute="route('dashboard.projects.destroy', deleteProject)"
        @close="onDelete"
    />
</template>

<script>
import Project from "@/Shared/Project/Project";
import ModalApiDelete from "../Modal/ModalApiDelete.vue";

export default {
    components: {
        Project,
        ModalApiDelete
    },
    data() {
        return {
            isHidden: null,
            deleteProject: false
        };
    },
    props: {
        projects: Object
    },
    methods: {
        onDelete(success) {
            if (success) {
                this.$inertia.reload();
            }
            this.deleteProject = false;
        }
    }
};
</script>
