<template>
    <div class="bg-white" :tabindex="tabindex" @blur="open = false">
        <div class="justify-between py-3 pl-4 pr-3 cursor-pointer flex items-center text-gray-900 text-base rounded-3xl dropdownShadow" :class="{ 'bg-gray-300 py-1': open }" @click="open = !open">
            {{ selected }}
            <div class="flex" :class="{ 'rounded-full h-10 w-10 iconBackground flex items-center ml-1 -mr-2': open }">
                <img class="justify-end" :class="{ 'ml-2': open }" src="/assets/icons/arrow-caret-down.png" alt="" />
            </div>
        </div>
        <div class="absolute bg-white w-full dropdownShadow rounded-3xl" :class="{ hidden: !open }">
            <div
                class="my-1 cursor-pointer text-base usage text-gray-700 mx-2"
                v-for="option in options"
                @click="
                    selected = option;
                    open = false;
                "
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            open: false,
            checkPurpose: false
        };
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        default: {
            type: String,
            required: false,
            default: null
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0
        },
        modelValue: {
            type: String,
            required: false
        }
    },
    computed: {
        selected: {
            get() {
                return this.modelValue ?? this.default;
            },
            set(value) {
                this.$emit("update:modelValue", value);
                this.checkPurpose = true;
            }
        }
    }
};
</script>
