<template>
    <div class="modal top-0 left-0 z-100 fixed flex justify-center items-center w-full h-screen">
        <div class="modal-backdrop bg-gray-900 opacity-50 overflow-y-hidden w-full h-screen"></div>
        <div class="modal-inner bg-white rounded-2xl top-66 p-4 absolute dropdownShadow" style="min-width: 344px">
            <a @click.prevent="close" class="absolute right-2 w-10 h-10 -top-12 close cursor-pointer bg-gray-900 flex rounded-full p-1.5">
                <img src="/assets/icons/menu-close-white.png" alt="" />
            </a>
            <div class="modal-header text-center text-primary-500">
                <slot name="modal-header" v-bind="{ close }"></slot>
            </div>
            <div class="modal-content">
                <slot name="modal-content" v-bind="{ close }"></slot>
            </div>
            <div class="modal-footer">
                <slot name="modal-footer" v-bind="{ close }"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        close() {
            this.$emit("close");
        }
    },
    mounted() {
        // optional
        document.addEventListener("keydown", e => {
            if (e.keyCode === 27 || e.key === 'Escape') {
                this.close();
            }
        });
    }
};
</script>
