<template>
    <div class="relative flex flex-wrap border border-gray-400 rounded-lg justify-between px-6 pt-6">
        <p class="text-xl font-semibold text-primary-500 mb-4 mr-4">{{ apiToken.name }}</p>
        <div>
            <div class="flex justify-end">
                <p class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg mr-2">
                    <span>300</span> calls
                </p>
                <div>
                    <img
                        v-on:click="isOpen = !isOpen"
                        class="cursor-pointer"
                        src="/assets/icons/menu-more.png"
                        id="menuMore"
                    />
                    <div
                        v-show="isOpen"
                        class="absolute top-14 right-4 rounded-3xl text-primary-500 bg-white p-2 z-50 dropdownShadow"
                    >
                        <div class="">
                            <a
                                @click.prevent="$emit('rename', apiToken)"
                                class="w-44 flex items-center feature"
                            >
                                <img class="mr-3" src="/assets/icons/edit.png" alt="" />
                                <span>Rename</span>
                            </a>
                            <a
                                @click.prevent="$emit('delete', apiToken)"
                                class="w-44 flex items-center feature"
                            >
                                <img class="mr-3" src="/assets/icons/delete.png" alt="" />
                                <span>Delete</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-6">
                <p class="text-gray-700 text-sm leading-6">
                    <span class="text-xs">LAST CALL:</span> Jul 29,2021, 01:23 pm
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: null
        };
    },
    props: {
        apiToken: Object,
        apiTokens: Array
    },
    mounted() {
        // optional
        document.addEventListener("keydown", e => {
            if (e.keyCode === 27 || e.key === "Escape") {
                this.isOpen = false;
            }
        });
        document.addEventListener("click", e => {
            if (e.target.id !== "menuMore") {
                this.isOpen = false;
            }
        });
    }
};
</script>
