import { usePage } from "@inertiajs/inertia-vue3";
import { ref } from "vue";

export class User {
    constructor() {
        Object.assign(this, window.user ?? usePage().props.value.auth.user);
    }
    settingsIncomplete() {
        return this.first_name == "" || this.last_name == "";
    }
    isFreeTier() {
        return this.stripe_id == null;
    }
    isAdmin() {
        return !!this.super;
    }
    loggedIn() {
        return !!this.id;
    }
    getAvatarUrl() {
        if (this.avatar) {
            return "/assets/" + this.avatar;
        }
        return "/assets/images/small-avatar.png";
    }
}

let _instance = null;
export default function () {

    return _instance ?? (_instance = ref(new User()));
}
