<template>
    <div
        class="relative flex flex-wrap border border-gray-400 rounded-lg justify-between px-6 pt-6"
    >
        <Link
            :href="route('dashboard.projects.show', [project.id])"
            class="text-xl font-semibold text-primary-500 hover:underline mb-4 mr-4"
            >{{ project.name }}</Link
        >
        <div>
            <div class="flex justify-end">
                <div class="flex">
                    <p class="px-2 text-primary-500 text-llg bg-primary-50 rounded-lg mr-2">
                        <span>3</span> API-keys in use
                    </p>
                    <p class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg mr-2">
                        <span>300</span> calls
                    </p>
                </div>
                <div>
                    <img
                        v-on:click="isOpen = !isOpen"
                        class="cursor-pointer"
                        src="/assets/icons/menu-more.png"
                        id="menuMore"
                    />
                    <div
                        v-show="isOpen"
                        class="absolute top-14 right-4 rounded-3xl text-primary-500 bg-white p-2 z-50 dropdownShadow"
                    >
                        <div class="">
                            <Link
                                :href="route('dashboard.projects.edit', [project.id])"
                                class="w-44 flex items-center feature"
                            >
                                <img class="mr-3" src="/assets/icons/edit.png" alt="" />
                                <span>Edit</span>
                            </Link>
                            <a
                                @click.prevent="$emit('delete', project)"
                                class="w-44 flex items-center feature"
                            >
                                <img class="mr-3" src="/assets/icons/delete.png" alt="" />
                                <span>Delete</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="py-4">
                <p class="text-gray-700 text-sm leading-6">
                    <span class="text-xs">DURING:</span> Jul 26, 2021 - Aug 01, 2021
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOpen: null,
        };
    },
    emits: ["delete"],
    props: {
        project: Object,
    },
    mounted() {
        // optional
        document.addEventListener("keydown", e => {
            if (e.keyCode === 27) {
                this.isOpen = false;
            }
        });
        document.addEventListener("click", e => {
            if (e.target.id !== "menuMore") {
                this.isOpen = false;
            }
        });
    },
};
</script>
