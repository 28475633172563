<template>
    <ModalBase @close="onClose">
        <template v-slot:modal-content>
            <div v-if="tokenCreated" class="mb-4">
                <p class="text-center text-primary-500 mb-4">API Key successfully created</p>
                <object class="mx-auto" data="/assets/icons/success-mark.svg" width="96" height="96"></object>
                <div class="text-center">
                    <small class="text-primary-500">Remember to copy and save it.</small>
                </div>
                <input v-model="tokenCreated" readonly @click="$event.target.select()" class="text-gray-900 w-full rounded-3xl border border-solid border-gray-300 px-4 py-3 mt-2" />
            </div>
            <div v-else class="mb-8">
                <label class="block text-gray-700 text-sm leading-6 ml-1 mb-1">Enter API Key Name</label>
                <div class="flex relative items-center">
                    <input
                        v-model="form.name"
                        name="name"
                        required
                        class="text-gray-900 w-full rounded-3xl border border-solid border-gray-300 focus:border-gray-700 placeholder-gray-700 px-4 py-3"
                        placeholder=""
                        type="text"
                    />
                </div>
            </div>
        </template>
        <template v-slot:modal-footer="{ close }">
            <div v-if="tokenCreated" class="flex gap-6 justify-center">
                <button @click="close" class="btn btn-help flex items-center border-none text-white bg-primary-500">
                    <span class="">Continue to Project</span>
                    <img class="ml-3" src="/assets/icons/arrow-right-white.png" alt="" />
                </button>
            </div>
            <div v-else class="flex gap-6 justify-center">
                <button @click="submit" :disabled="!isValid" class="btn btn-help flex items-center border-none text-gray-700" :class="!isValid ? 'bg-gray-400' : 'bg-secondary-500'">
                    <span class="">Create new API Key</span>
                    <img class="ml-3" src="/assets/icons/edit-plus-circle.png" alt="" />
                </button>
            </div>
        </template>
    </ModalBase>
</template>

<script>
import ModalBase from "@/Shared/Modal/ModalBase";

export default {
    components: {
        ModalBase
    },
    props: {
        project: Object,
        apiTokens: Object
    },
    data() {
        return {
            form: {
                name: ""
            },
            tokenCreated: false
        };
    },
    computed: {
        isValid() {
            return this.form.name && this.apiTokens.find(o => o.name === this.form.name) === undefined;
        }
    },
    methods: {
        async submit() {
            const res = await fetch(this.route("dashboard.projects.create_token", this.project), {
                method: "POST",
                body: JSON.stringify(this.form),
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-CSRF-Token": this.$page.props.csrf_token
                }
            });
            const body = await res.json();
            if (body.success) {
                this.tokenCreated = body.token;
            }
        },
        onClose() {
            if (this.tokenCreated) {
                this.$inertia.reload();
            }

            this.$emit("close");
        }
    }
};
</script>
