<template>
    <div class="inline-flex signUp mr-8 relative pr-24">
        <template v-if="success">
            <div
                class="btn btn-secondary flex justify-center items-center whitespace-nowrap z-20 absolute right-0 top-0 h-full"
            >
                Please check your emails
                <SvgVue class="ml-3 h-full" icon="success" />
            </div>
        </template>
        <InputVue
            v-model="email"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
            ref="input"
            id="sign-up-email"
            :error="invalid && 'Please correct your email adress'"
            :disabled="loading"
            placeholder="Enter work email"
            class="btn btn-input w-62 pr-12"
            :class="{ 'border-danger-500': invalid, invisible: success }"
        />
        <button
            @click="onClick"
            :disabled="loading || email == ''"
            class="btn btn-secondary flex whitespace-nowrap cursor-pointer z-10 absolute right-0 top-0 h-full"
            :class="{ invisible: success }"
        >
            <template v-if="isEdit"> Sign up </template>
            <template v-else> Start for free </template>
            <img
                class="ml-3 transitionArrow"
                src="/assets/icons/arrow-right-primary.png"
            />
        </button>
    </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import SvgVue from "svg-vue3";
import InputVue from "@/Shared/CustomInput.vue";
import route from "@/route";
import csrf_token from "@/csrf_token";

const input = ref(null);
const email = ref("");
const inputFocused = ref(false);
const success = ref(false);
const invalid = ref(false);
const loading = ref(false);

setTimeout(() => {
    email.value = input.value.$refs.native.value;
}, 1000);

const isEdit = computed(() => inputFocused.value || email.value.length > 0);

watch(inputFocused, focused => {
    if (focused) {
        invalid.value = false;
    }
});

function onClick() {
    if (!isEdit.value) {
        input.value.$refs.native.focus();
        return;
    }

    if (validate()) {
        register();
    }
}

async function register() {
    loading.value = true;
    const res = await fetch(route("register.token.request"), {
        method: "POST",
        body: JSON.stringify({
            email: email.value
        }),
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CSRF-Token": csrf_token()
        }
    });
    loading.value = false;
    const body = await res.json();
    success.value = body.success;
    invalid.value = !body.success;
}

function validate() {
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    invalid.value = !emailRegex.test(email.value);
    return !invalid.value;
}
</script>

<style lang="scss" scoped>
:deep(.error-icon) {
    right: 3rem;
}
</style>
