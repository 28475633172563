<template>
    <div class="flex justify-between">
        <div class="flex text-gray-900 rounded-3xl">
            <div class="w-30">
                <CustomSelect
                    :options="['Usage', 'Costs']"
                    v-model="displayMode"
                />
            </div>
            <div class="ml-4">
                <p class="text-gray-700 text-sm leading-6">{{ date }}</p>
                <p
                    v-if="displayMode === 0"
                    class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg float-left mt-1"
                >
                    {{ calls }} calls
                </p>
                <p
                    v-if="displayMode !== 0"
                    class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg float-left mt-1"
                >
                    0.00 €
                </p>
            </div>
        </div>
        <div class="hidden md:block">
            <a id="" href="#" class="w-full flex content-center">
                <div class="flex z-999">
                    <button
                        class="rounded-full h-12 w-12 bg-gray-400 text-gray-700"
                    >
                        <img
                            class="mx-auto"
                            src="/assets/icons/arrow-simple-right.png"
                            alt=""
                        />
                    </button>
                </div>
            </a>
        </div>
    </div>
    <div>
        <div class="border border-primary-200 rounded-3xl h-60 mt-4"></div>
        <div
            class="flex md:grid md:grid-cols-5 justify-between mb-4 text-gray-700 text-sm uppercase text-center leading-6 px-4"
        >
            <div
                v-for="(option, key) in [
                    'Week',
                    'Month',
                    '3 Months',
                    'Year',
                    'Max'
                ]"
                @click="selectedTime = key"
                :class="{ active: selectedTime == key }"
                class="w-full"
            >
                <p
                    class="py-3 px-2 cursor-pointer whitespace-nowrap text-sm"
                    :class="{
                        'text-primary-500 font-semibold': selectedTime == key
                    }"
                >
                    {{ option }}
                </p>
                <div
                    class="h-1"
                    :class="{
                        'bg-primary-500': selectedTime == key,
                        'bg-gray-300': selectedTime !== key
                    }"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import CustomSelect from "../CustomSelect.vue";
import { DateTime } from "luxon";

const date = ref(DateTime.now().toLocaleString(DateTime.DATETIME_MED));
const calls = ref(0);
const selectedTime = ref(0);
const displayMode = ref(0);
</script>

<style lang="scss" scoped></style>
