<template>
    <div class="text-white">
        <div class="flex justify-between w-full">
            <a @click="show = !show" class="w-full flex cursor-pointer opacity-60 items-center">
                <p class="text-sm leading-6 uppercase flex-1 text-white">{{ title }}</p>
                <svg class="h-6 w-6 text-primary-200 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </a>
        </div>
        <div v-if="show" class="content text-white mt-7">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        show: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {};
    }
};
</script>
