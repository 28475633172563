<template>
    <div>
        <modal-success
            v-if="modalSuccess.active"
            @open-modal="openModal"
            :data="modalSuccess.data"
        />
        <nav class="fixed z-50 bg-primary-600 w-full">
            <div class="mx-auto px-4 lg:px-12 py-2 lg:py-4">
                <div class="flex justify-between items-center">
                    <div class="flex-shrink-0 flex">
                        <Link
                            class="flex items-center justify-center"
                            :href="route('dashboard.index')"
                        >
                            <img class="w-12 lg:w-16" :src="'/assets/logo.png'" alt="logo" />
                            <h5 class="font-bold italic text-white ml-2">dashboard</h5>
                        </Link>
                    </div>
                    <div class="justify-center items-center flex">
                        <UserAvatar />
                        <!-- Hamburger button -->
                        <img
                            @click="openNavMenu"
                            v-show="hamburgerActive"
                            class="hamburger lg:hidden cursor-pointer"
                            src="/assets/icons/menu-hamburger.png"
                            alt=""
                        />
                        <img
                            @click="closeNavMenu"
                            v-show="closeButton"
                            class="closeMobileMenu lg:hidden cursor-pointer"
                            src="/assets/icons/menu-close-secondary.png"
                            alt=""
                        />
                    </div>

                </div>
            </div>
        </nav>
        <div class="flex flex-row pt-24">
            <!-- Side bar -->
            <div
                class="hidden lg:block fixed sidebar bg-primary-500 divide-y divide-primary-400 whitespace-nowrap w-52 min-h-screen"
            >
                <menu-dropdown :title="'Projects'" :show="true" class="px-4 py-6">
                    <!-- Projects -->
                    <div v-for="project in projects" :key="project.id" class="mb-6">
                        <Link :href="route('dashboard.projects.show', [project])"
                            ><p class="hover:underline text-white">{{ project.name }}</p></Link
                        >
                    </div>
                    <div class="pt-4">
                        <Link
                            class="flex hover:underline text-secondary-500"
                            :href="route('dashboard.projects.create')"
                        >
                            <img class="mr-2" src="/assets/icons/create.png" />
                            <p class="text-secondary-500">Create Project</p>
                        </Link>
                    </div>
                </menu-dropdown>
                <menu-dropdown :title="'Your Projects'" :show="true" class="px-4 py-6 relative">
                    <div class="settingsNav">
                        <div
                            v-if="user.settingsIncomplete()"
                            class="settingsAlert absolute rounded-3xl bg-white shadow-lg top-6 left-4"
                        >
                            <p class="px-4 py-3 text-gray-700">
                                Please setup your Account Settings
                            </p>
                        </div>
                        <div class="flex justify-between text-white">
                            <Link
                                class="flex mb-6 hover:underline"
                                :href="route('dashboard.users.edit', [user.id])"
                            >
                                <img class="mr-2" src="/assets/icons/settings.png" />
                                Settings
                            </Link>
                            <div
                                v-if="user.settingsIncomplete()"
                                class="flex justify-center items-center rounded-full h-5 w-5 bg-danger-500 leading-5 font-semibold"
                            >
                                1
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between text-white">
                        <Link class="flex hover:underline" :href="route('dashboard.billing')">
                            <img class="mr-2" src="/assets/icons/billing.png" />
                            Billing
                        </Link>
                        <div
                            class="flex justify-center items-center rounded-full h-5 w-5 bg-danger-500 leading-5 font-semibold"
                        >
                            1
                        </div>
                    </div>
                </menu-dropdown>
                <menu-dropdown :title="'Ressources'" :show="true" class="text-white px-4 py-6">
                    <a class="flex mb-6 hover:underline" href="https://documenter.getpostman.com/view/19794848/UVkqsute">
                        <img class="mr-2" src="/assets/icons/ressources.png" />
                        Documentation
                    </a>
                    <a class="flex mb-6 hover:underline" href="/blog">
                        <img class="mr-2" src="/assets/icons/ressources.png" />
                        Tutorials
                    </a>
                    <a class="flex mb-6 hover:underline" href="https://github.com/mobilify-ai/">
                        <img class="mr-2" src="/assets/icons/ressources.png" />
                        100+ SDKs
                    </a>
                    <a
                        class="flex hover:underline border-b border-primary-400 px-4 -mx-4 pb-5"
                        href="/contact"
                    >
                        <img class="mr-2" src="/assets/icons/ressources.png" />
                        Support
                    </a>
                </menu-dropdown>
            </div>
            <div class="main flex-1 lg:ml-52">
                <slot class="w-100" />
            </div>
        </div>

        <!-- mobile side bar -->
        <div
            v-show="sidebar"
            class="dashboard-overlay dashboard-slidedown lg:hidden justify-end bg-primary-500 divide-y divide-primary-400 whitespace-nowrap w-52 min-h-screen"
            :class="{ open: open }"
            id="sidebar"
        >
            <menu-dropdown :title="'Projects'" :show="true" class="px-4 py-6">
                <!-- Projects -->
                <div v-for="project in projects" :key="project.id" class="mb-6">
                    <Link :href="route('dashboard.projects.show', [project])"
                        ><p class="hover:underline text-white">{{ project.name }}</p></Link
                    >
                </div>
                <div class="pt-4">
                    <Link
                        class="flex hover:underline text-secondary-500"
                        :href="route('dashboard.projects.create')"
                    >
                        <img class="mr-2" src="/assets/icons/create.png" />
                        <p class="text-secondary-500">Create Project</p>
                    </Link>
                </div>
            </menu-dropdown>
            <menu-dropdown :title="'Your Projects'" :show="true" class="px-4 py-6 relative">
                <div class="settingsNav">
                    <div
                        class="settingsAlert absolute rounded-3xl bg-white shadow-lg top-6 left-4"
                    >
                        <p class="px-4 py-3 text-gray-700">Please setup your Account Settings</p>
                    </div>
                    <div class="flex justify-between text-white">
                        <Link
                            class="flex mb-6 hover:underline"
                            :href="route('dashboard.users.edit', [user.id])"
                        >
                            <img class="mr-2" src="/assets/icons/settings.png" />
                            Settings
                        </Link>
                        <button
                            class="rounded-full h-5 w-5 bg-danger-500  leading-5 font-semibold"
                        >
                            1
                        </button>
                    </div>
                </div>
                <div class="flex justify-between text-white">
                    <Link class="flex hover:underline" :href="route('dashboard.billing')">
                        <img class="mr-2" src="/assets/icons/billing.png" />
                        Billing
                    </Link>
                    <button
                        class="rounded-full h-5 w-5 bg-danger-500 leading-5 font-semibold"
                    >
                        1
                    </button>
                </div>
            </menu-dropdown>
            <menu-dropdown
                :title="'Ressources'"
                :show="true"
                class="text-white px-4 py-6 min-h-screen"
            >
                <Link class="flex mb-6 hover:underline" href="">
                    <img class="mr-2" src="/assets/icons/ressources.png" />
                    Documentation
                </Link>
                <Link class="flex mb-6 hover:underline" href="">
                    <img class="mr-2" src="/assets/icons/ressources.png" />
                    Tutorials
                </Link>
                <Link class="flex mb-6 hover:underline" href="">
                    <img class="mr-2" src="/assets/icons/ressources.png" />
                    100+ SDKs
                </Link>
                <Link
                    class="flex hover:underline border-b border-primary-400 px-4 -mx-4 pb-5"
                    href=""
                >
                    <img class="mr-2" src="/assets/icons/ressources.png" />
                    Support
                </Link>
            </menu-dropdown>
        </div>
    </div>
</template>

<script>
import MenuDropdown from "./MenuDropdown.vue";
import ModalSuccess from "@/Shared/Modal/ModalSuccess";
import UserAvatar from "@/Shared/UserAvatar";
import user from "@/user";

export default {
    components: {
        MenuDropdown,
        ModalSuccess,
        UserAvatar
    },
    props: {
        projects: Object,
        users: Object
    },
    data() {
        return {
            avatarDropdown: false,
            modalSuccess: {
                active: false,
                data: {}
            },
            sidebar: true,
            open: false,
            hamburgerActive: true,
            closeButton: false
        };
    },
    computed: {
        user() {
            return user().value;
        }
    },
    methods: {
        openModal(type, data) {
            console.log("iam here");
            this[type].active = !this[type].active;
            this[type].data = data;
        },
        openNavMenu() {
            this.open = !this.open;
            this.hamburgerActive = !this.hamburgerActive;
            this.closeButton = !this.closeButton;
        },
        closeNavMenu() {
            this.open = !this.open;
            this.hamburgerActive = !this.hamburgerActive;
            this.closeButton = !this.closeButton;
        }
    }
};
</script>
