<template>
    <modal-base>
        <template v-slot:modal-header>
            <div class="title">{{ data.title }}</div>
        </template>

        <template v-slot:modal-content>
            <object class="mx-auto" data="/assets/icons/success-mark.svg" width="96" height="96"></object>
        </template>

        <template v-slot:modal-footer>
            <Link class="btn btn-primary block" :href="data.buttonLink">
                {{ data.buttonText }}
                <object class="inline" data="/assets/icons/arrow-right-white.png"></object>
            </Link>
        </template>
    </modal-base>
</template>

<script>
import ModalBase from "@/Shared/Modal/ModalBase";

export default {
    components: {
        "modal-base": ModalBase
    },
    props: {
        data: {
            type: Object,
            default: function () {
                return { title: "", buttonLink: "", buttonText: "" };
            }
        }
    },
    data() {
        return {};
    },

    methods: {}
};
</script>
