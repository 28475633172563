<template>
    <img
        @click="avatarDropdown = !avatarDropdown"
        class="cursor-pointer mr-4 h-12"
        :src="user.getAvatarUrl()"
        alt=""
        id="avatar"
    />
    <div
        v-show="avatarDropdown"
        class="fixed rounded-3xl dropdownShadow top-20 right-12 text-primary-500 bg-white px-2 py-2"
    >
        <div>
            <a v-if="isDashboard" href="/" class="flex items-center feature w-44">
                <img class="mr-2" src="/assets/icons/avatar-device.png" alt="logo" />
                <span>Mobilify.ai</span>
            </a>
            <a v-else href="/dashboard" class="flex items-center feature w-40">
                <SvgVue icon="dashboard" class="mr-2 h-6" />
                <span>Dashboard</span>
            </a>
            <Link
                class="flex items-center feature"
                :href="route('dashboard.users.edit', [user.id])"
            >
                <img class="mr-2" src="/assets/icons/avatar-settings.png" alt="logo" />
                <span>Settings</span>
                <button v-if="user.settingsIncomplete()" class="rounded-full h-5 w-5 bg-danger-500 leading-5 font-semibold text-white ml-auto mr-2">
                    1
                </button>
            </Link>
            <Link class="flex items-center feature" :href="route('dashboard.billing')">
                <img class="mr-2" src="/assets/icons/avatar-billing.png" alt="logo" />
                <span>Billing</span>
                <button class="rounded-full h-5 w-5 bg-danger-500 leading-5 font-semibold text-white ml-auto mr-2">1</button>
            </Link>
            <form
                :action="route('logout')"
                ref="logoutForm"
                @click="$refs.logoutForm.submit()"
                class="flex items-center feature"
                method="POST"
            >
                <input type="hidden" name="_token" :value="csrf_token()" />
                <img class="mr-2" src="/assets/icons/avatar-signOut.png" alt="logo" />
                <button type="submit"><span>Sign Out</span></button>
            </form>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { Link } from "@inertiajs/inertia-vue3";
import SvgVue from "svg-vue3";
import route from "@/route";
import getUser from "@/user";

const avatarDropdown = ref(false);
const isDashboard = computed(() => window.location.pathname.match(/^\/dashboard/));

const user = computed(() => {
    return getUser().value;
});

document.addEventListener("keydown", e => {
    if (e.keyCode === 27 || e.key === "Escape") {
        avatarDropdown.value = false;
    }
});
document.addEventListener("click", e => {
    if (e.target.id !== "avatar") {
        avatarDropdown.value = false;
    }
});
</script>

<style lang="scss" scoped></style>
