<template>
    <ModalBase @close="$emit('close',success)">
        <template v-slot:modal-content>
            <div v-if="success" class="mb-4">
                <p class="text-center text-primary-500 mb-4">
                    {{ subjectTitle }} successfully deleted.
                </p>
                <object
                    class="mx-auto"
                    data="/assets/icons/success-mark.svg"
                    width="96"
                    height="96"
                ></object>
            </div>
            <div v-else class="mb-8">
                <p class="text-primary-500 text-center mb-5 whitespace-normal">
                    You are going to delete your {{ subjectTitle }} <br />„<strong>{{
                        subjectName
                    }}</strong
                    >“. This action cannot be undone.
                </p>
                <CustomInput
                    :label="`Enter ${subjectTitle} name to confirm`"
                    v-model="form.name"
                    required
                    class="text-gray-900 w-full rounded-3xl border border-solid border-gray-300 focus:border-gray-700 placeholder-gray-700 px-4 py-3"
                    placeholder=""
                    type="text"
                />
            </div>
        </template>

        <template v-slot:modal-footer="{ close }">
            <div v-if="success" class="flex gap-6 justify-center">
                <button
                    @click="close"
                    class="btn btn-help flex items-center border-none text-white bg-primary-500"
                >
                    <span class="">{{ successButtonText }}</span>
                    <img class="ml-3" src="/assets/icons/arrow-right-white.png" alt="" />
                </button>
            </div>
            <div v-else class="flex gap-6 justify-center">
                <button @click="close" class="btn btn-help flex items-center">
                    <img class="mr-3" src="/assets/icons/menu-close-primary.png" alt="" />
                    <span>Cancel</span>
                </button>
                <div>
                    <button
                        @click="submit"
                        :disabled="!isValid"
                        class="btn btn-help flex items-center border-none"
                        :class="!isValid ? 'bg-gray-400 text-gray-700' : 'bg-danger-500 text-white'"
                    >
                        Delete
                        <img
                            class="ml-3"
                            :src="
                                !isValid
                                    ? '/assets/icons/basic-trash-full.png'
                                    : '/assets/icons/basic-trash-full-white.png'
                            "
                            alt=""
                        />
                    </button>
                </div>
            </div>
        </template>
    </ModalBase>
</template>

<script>
import ModalBase from "@/Shared/Modal/ModalBase";
import CustomInput from "../CustomInput.vue";

export default {
    components: {
        ModalBase,
        CustomInput
    },
    data() {
        return {
            form: {
                name: ""
            },
            success: false
        };
    },
    props: {
        subjectName: String,
        subjectTitle: String,
        deletionRoute: String,
        successButtonText: String
    },
    computed: {
        isValid() {
            return this.form.name === this.subjectName;
        }
    },
    methods: {
        async submit() {
            const res = await fetch(this.deletionRoute, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-CSRF-Token": this.csrf_token()
                }
            });
            const body = await res.json();
            this.success = body.success;
        }
    }
};
</script>
