<template>
    <div v-for="(option, key) in options" @click="changeSelected(key)" :class="{ active: selected == key }" class="w-full">
        <p class="py-3 px-2 cursor-pointer whitespace-nowrap text-sm" :class="{ 'text-primary-500 font-semibold': selected == key }">{{ option }}</p>
        <div class="h-1" :class="{ 'bg-primary-500': selected == key, 'bg-gray-300': selected !== key }"></div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        default: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            barActive: false,
            selected: 0
        };
    },

    methods: {
        changeSelected(key) {
            this.selected = key;
        }
    }
};
</script>
