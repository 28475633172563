<template>
    <div class="bg-white flex items-center" :tabindex="tabindex" @blur="open = false">
        <div>
            <div
                class="py-3 pl-4 pr-3 cursor-pointer flex items-center text-gray-900 text-base bg-primary-25 border border-solid border-transparent rounded-3xl"
                :class="{ 'bg-gray-300 py-1': open }"
                @click="open = !open"
            >
                {{ selected }}
                <div :class="{ 'rounded-full h-10 w-10 iconBackground flex items-center ml-1 -mr-2': open }">
                    <img class="ml-3" :class="{ 'ml-2': open }" src="/assets/icons/arrow-caret-down.png" alt="" />
                </div>
            </div>
            <div class="absolute bg-white w-28 dropdownShadow rounded-3xl" :class="{ hidden: !open }">
                <div
                    class="my-1 cursor-pointer text-base usage text-gray-700 mx-2"
                    v-for="option in options"
                    @click="
                        selected = option;
                        open = false;
                    "
                >
                    {{ option }}
                </div>
            </div>
        </div>
        <div class="ml-4">
            <p class="text-gray-700 text-sm leading-6">Jul 29, 2021, 01:23 pm</p>
            <p v-if="selected === this.default" class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg float-left mt-1">185 calls</p>
            <p v-if="selected !== this.default" class="px-2 text-primary-500 text-llg bg-secondary-100 rounded-lg float-left mt-1">128.12 $</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        default: {
            type: String,
            required: false,
            default: null
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            selected: this.default,
            open: false
        };
    }
};
</script>
