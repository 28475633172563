<template>
    <select v-model="passedModel" ref="nativeSelect" class="hidden">
        <option v-for="(text, value) in options" :value="value"></option>
    </select>
    <label
        v-if="label"
        v-bind="labelAttrs"
        :for="inputId"
        class="block text-gray-700 text-sm leading-4 ml-3 mb-1"
        v-html="label"
    ></label>
    <div class="relative selectButton">
        <input
            :id="inputId"
            v-model="displayValue"
            v-bind="$attrs"
            @focus="open = true"
            @blur="inputBlur"
            readonly
            role="button"
            ref="native"
            class="text-gray-900 w-full shadow-md rounded-3xl border bg-primary-25 border-primary-25 focus:bg-gray-300 focus:border-gray-300 focus:outline-none focus:shadow-none placeholder-gray-700 px-4 py-3"
        />
        <div
            class="absolute h-full p-1 right-0 top-0 cursor-pointer"
            @click="!open && native.focus()"
        >
            <SvgVue icon="DropDown" class="h-full p-2 rounded-full selectIcon"></SvgVue>
        </div>
    </div>
    <div class="relative">
        <transition>
            <div
                class="absolute top-0 bg-white w-full dropdownShadow rounded-3xl"
                :class="{ hidden: !open }"
            >
                <div
                    v-for="(text, value) in options"
                    :key="value"
                    class="my-1 rounded-2xl px-2 py-1 leading-6 cursor-pointer text-base text-gray-700 mx-2 selectItem"
                    @click="
                        passedModel = value;
                        displayValue = text;
                        open = false;
                    "
                    v-html="text"
                ></div>
            </div>
        </transition>
    </div>
    <span v-if="error" v-bind="errorAttrs" class="error" v-html="error"></span>
</template>

<script setup>
import { useAttrs, computed, ref } from "vue";
import SvgVue from "svg-vue3";
const $attrs = useAttrs();

const props = defineProps({
    modelValue: undefined,
    labelAttrs: Object,
    errorAttrs: Object,
    label: String,
    error: String,
    options: Object
});
const emit = defineEmits(["update:modelValue"]);

const inputId = computed(
    () => $attrs.id ?? "input-" + (Math.random() + 1).toString(36).substring(7)
);

const passedModel = computed({
    get: () => props.modelValue ?? $attrs.value,
    set: value => emit("update:modelValue", value)
});

const displayValue = ref(props.options[props.modelValue]);

const open = ref(false);

function inputBlur() {
    setTimeout(() => {
        open.value = false;
    }, 100);
}

const native = ref(null);
const nativeSelect = ref(null);
</script>

<style lang="scss" scoped>
.selectItem:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.selectIcon:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.selectButton:hover input:not(:focus) {
    @apply border-white bg-white;
}
</style>
