// Home Page -> Products & Developer
const productSub = document.getElementById("products");
const developerSub = document.getElementById("developer");
const arrowRotate = document.querySelector(".arrowRotate");
const pricing = document.getElementById("pricing");
const blog = document.getElementById("blog");

// set active class on menuItems when hovered
const navItems = document.querySelectorAll("nav .nav-items-wrapper .nav-item");
for (let i = 0; i < navItems.length; i++) {
    navItems[i].addEventListener("mouseenter", function (event) {
        event.target.classList.add("active");
    });

    navItems[i].addEventListener("mouseleave", function (event) {
        event.target.classList.remove("active");
    });
}

// mobile nav menu
const mobilePages = document.querySelectorAll(".navMenu .mobileNavIcons");
for (let i = 0; i < mobilePages.length; i++) {
    mobilePages[i].addEventListener("click", e => {
        const navMenu = e.target.closest(".navMenu");
        navMenu.querySelector(".mobileMenu").classList.toggle("hidden");
        navMenu.querySelector(".navIconDown").classList.toggle("hidden");
        navMenu.querySelector(".navIconUp").classList.toggle("hidden");
        navMenu.querySelector(".page-title").classList.toggle("text-white");
        navMenu.querySelector(".page-title").classList.toggle("text-secondary-500");
    });
}

// Mobile Main Nav Hamburger Menu
const hamburger = document.querySelector(".hamburger");
const closeMobileMenu = document.querySelector(".closeMobileMenu");
const overlaySlide = document.querySelector(".overlay-slidedown");

hamburger.addEventListener("click", () => {
    hamburger.classList.add("hidden");
    closeMobileMenu.classList.remove("hidden");
    overlaySlide.classList.add("open");
});

closeMobileMenu.addEventListener("click", () => {
    closeMobileMenu.classList.add("hidden");
    hamburger.classList.remove("hidden");
    overlaySlide.classList.remove("open");
});

// Blog Search
const searchIcon = document.querySelector(".searchIcon");
const searchCancel = document.querySelector(".searchCancel");
const blogSubmenu = document.querySelector(".blogSubmenu");
const searchBar = document.querySelector(".searchBar");

searchIcon?.addEventListener("click", () => {
    blogSubmenu.classList.add("hidden");
    searchBar.classList.remove("hidden");
});
searchCancel?.addEventListener("click", () => {
    blogSubmenu.classList.remove("hidden");
    searchBar.classList.add("hidden");
});

//Blog Mobile Submenu
const blogSlideDown = document.querySelector(".overlay-slidedown-blog");
const openBlogNav = document.querySelector(".open-blog");
const closeBlogNav = document.querySelector(".close-blog");

openBlogNav?.addEventListener("click", () => {
    openBlogNav.classList.add("hidden");
    closeBlogNav.classList.remove("hidden");
    blogSlideDown.classList.add("open");
    document.body.classList.add("fixed");
});

closeBlogNav?.addEventListener("click", () => {
    closeBlogNav.classList.add("hidden");
    openBlogNav.classList.remove("hidden");
    blogSlideDown.classList.remove("open");
    document.body.classList.remove("fixed");
});

import './app.js';
