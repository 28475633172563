<template>
    <label
        v-if="label"
        v-bind="labelAttrs"
        :for="inputId"
        class="block text-gray-700 text-sm leading-4 ml-3 mb-1"
        v-html="label"
    ></label>
    <div class="flex relative items-center">
        <input
            :id="inputId"
            v-model="passedModel"
            v-bind="$attrs"
            ref="native"
            class="text-gray-900 w-full rounded-3xl border border-gray-300 focus:border-gray-500 focus:outline-none placeholder-gray-700 px-4 py-3 shadow-none"
        />
        <svg-vue v-if="error" class="error-icon h-6 absolute right-4 text-danger-500 fill-current" icon="attention" />
        <div v-if="error" class="error-arrow bg-transparent w-2 absolute left-4 -bottom-2 border-8 border-transparent border-b-danger-500"></div>
        <span v-if="error" class="error-label absolute left-3 top-14 bg-danger-500 px-1 rounded-sm" v-html="error"></span>
    </div>
</template>

<script setup>
import { useAttrs, computed, ref } from "vue";
import SvgVue from "svg-vue3";

const $attrs = useAttrs();
const props = defineProps({
    modelValue: String,
    labelAttrs: Object,
    label: String,
    error: [String, Boolean]
});
const inputId = computed(
    () => $attrs.id ?? "input" + (Math.random() + 1).toString(36).substring(7)
);
const emit = defineEmits(["update:modelValue"]);
const passedModel = computed({
    get: () => props.modelValue ?? $attrs.value,
    set: value => emit("update:modelValue", value)
});

const native = ref(null);
</script>

<style lang="scss" scoped></style>
