<template>
    <ModalBase @close="$emit('close')">
        <template v-slot:modal-content>
            <div v-if="success" class="mb-4">
                <p class="text-center text-primary-500 mb-4">API Key successfully renamed.</p>
                <object class="mx-auto" data="/assets/icons/success-mark.svg" width="96" height="96"></object>
            </div>
            <div v-else class="mb-8">
                <label class="block text-gray-700 text-sm leading-6 ml-1 mb-1">Enter new API Key-name</label>
                <div class="flex relative items-center">
                    <input
                        v-model="form.name"
                        required
                        class="text-gray-900 w-full rounded-3xl border border-solid border-gray-300 focus:border-gray-700 placeholder-gray-700 px-4 py-3"
                        placeholder=""
                        type="text"
                    />
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div v-if="success" class="flex gap-6 justify-center">
                <button @click="$emit('close')" class="btn btn-help flex items-center border-none text-white bg-primary-500">
                    <span class="">Continue to Project</span>
                    <img class="ml-3" src="/assets/icons/arrow-right-white.png" alt="" />
                </button>
            </div>
            <div v-else class="flex gap-6 justify-center">
                <button @click="$emit('close')" class="btn btn-help flex items-center">
                    <img class="mr-3" src="/assets/icons/menu-close-primary.png" alt="" />
                    <span>Cancel</span>
                </button>
                <button
                    @click="submit"
                    :disabled="!isValid"
                    class="btn btn-help flex items-center border-none"
                    :class="!isValid ? 'bg-gray-400 text-gray-700' : 'bg-secondary-500 text-primary-500'"
                >
                    Rename
                    <img class="ml-3" src="/assets/icons/basic-trash-full.png" alt="" />
                </button>
            </div>
        </template>
    </ModalBase>
</template>

<script>
import ModalBase from "@/Shared/Modal/ModalBase";

export default {
    components: {
        ModalBase
    },
    data() {
        return {
            form: {
                name: ""
            },
            success: false
        };
    },
    props: {
        apiToken: Object,
        apiTokens: Array
    },
    computed: {
        isValid() {
            return this.form.name && this.apiTokens.find(o => o.name === this.form.name) === undefined;
        }
    },
    methods: {
        submit() {
            this.$inertia.put(this.route("dashboard.project_tokens.update", this.apiToken), this.form, {
                onSuccess: () => (this.success = true)
            });
        }
    }
};
</script>
